import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function Section4() {
  const treeQuery = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Tree.png" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <div
      data-anchor="page4"
      id="page4"
      class="pp-scrollable section section-4 "
    >
      <div class="scroll-wrap">
        <div
          class="section-bg bg-about"
          style={{
            backgroundimage: "url(require(../images/bg/about.jpg))",
          }}
        ></div>
        <div class="scrollable-content">
          <div class="vertical-title text-white  d-none d-lg-block">
            <span>about us</span>
          </div>
          <div class="vertical-centred">
            <div class="boxed boxed-inner">
              <div class="boxed">
                <div class="container">
                  <div class="intro">
                    <div class="row align-items-center">
                      <div class="col-lg-6 offset-xl-1">
                        <div class="experience-box">
                          <div class="experience-content">
                            <div class="experience-number">
                              {" "}
                              <Img
                                className="mr-3"
                                fixed={treeQuery.file.childImageSharp.fixed}
                                alt="tree on fire"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-5 mt-5 mt-xl-0">
                        <h2 class="title">
                          <span class="font-bold">¿Qué es</span> Jóvenes
                          Discípulos?
                        </h2>
                        <p className="text-white text-lg">
                          Jóvenes Discípulos no es
                          <span className="text-primary font-semibold">
                            {" ninguna religión"}
                          </span>
                          .
                          <br />
                          No pertenece a ninguna iglesia como tal. <br />
                          Es un proyecto que persigue
                          <span className="text-primary font-semibold">
                            {
                              " recoger material pueda ser de bendición a las personas "
                            }
                          </span>
                          no importa su denominación.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
