import React from "react"

export default function Landing() {
  return (
    <div data-anchor="page1" id="page1" class=" text-white section section-1">
      <div class="scroll-wrap">
        <div
          class="section-bg"
          style={
            {
              // backgroundImage: `url(${Background})`,
            }
          }
        ></div>
        <div class="scrollable-content">
          <div class="vertical-centred">
            <div class="boxed boxed-inner">
              <div class="boxed">
                <div class="container">
                  <div class="intro">
                    <div class="row">
                      <div class="col-md-8 col-lg-6">
                        <h1 class="text-5xl sm:text-8xl text-white leading-none">
                          <span class="text-primary">Bienvenid@,</span> a
                          Jóvenes Discípulos
                        </h1>
                        <a
                          class="popup-youtube"
                          target="__blank"
                          data-sal="flip-up"
                          data-sal-duration="1000"
                          data-sal-delay="300"
                          href="https://www.youtube.com/c/jovenesdiscipulos/videos"
                        >
                          <span class="icon ion-ios-play"></span>
                          ir al canal de youtube{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
