import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import "./styles/Menu.styl"

export default function Menu({ handleCloseMenu, style }) {
  console.log(style)
  useEffect(() => {
    console.log(style)
  }, [])
  return (
    <div className={`menu ${style}`}>
      <span
        onClick={handleCloseMenu}
        class="close-menu icon-cross2 right-boxed"
      ></span>
      <ul class="menu-list right-boxed">
        <li data-menuanchor="page1">
          <Link to="/">Home</Link>
        </li>
        <li data-menuanchor="page2">
          <Link to="/page-2">Blog</Link>
        </li>
        <li data-menuanchor="page3">
          <Link to="/texts">Material y revistas</Link>
        </li>
        <li data-menuanchor="page4">
          <Link to="/page-2">Descargar audios</Link>
        </li>
      </ul>
      <div class="menu-footer right-boxed">
        <div class="social-list">
          <a
            href="https://www.instagram.com/jovenes.discipulos/"
            class="icon ion-social-instagram"
            target="_blank"
          ></a>
          <a
            href="https://twitter.com/j_discipulos"
            class="icon ion-social-twitter"
            target="_blank"
          ></a>
          <a
            href="https://www.facebook.com/jovenesdiscipulosmx"
            class="icon ion-social-facebook"
            target="_blank"
          ></a>
          <a
            href="https://www.youtube.com/user/jovenesdiscipulos"
            class="icon ion-social-youtube"
            target="_blank"
          ></a>
        </div>
        <div class="copy">© ramsay 2019. all rights reseverd</div>
      </div>
    </div>
  )
}
