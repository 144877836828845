import React, { useState, Fragment, useRef } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import Menu from "./Menu"
import "./styles/Header.styl"

export default function Header() {
  const menu = useRef()
  const [menuStyle, setMenuStyle] = useState("menu-is-closed")

  function handleShowMenu() {
    style = "menu-is-open"
  }
  function handleCloseMenu() {
    style = "menu-is-closed"
  }

  var style = "menu-is-closed"

  const treeQuery = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Tree.png" }) {
        childImageSharp {
          fixed(width: 65, height: 45) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Fragment>
      <Menu
        handleCloseMenu={() => {
          setMenuStyle("menu-is-closed")
        }}
        style={menuStyle}
      />
      <header class="navbar navbar-fullpage boxed">
        <div class="navbar-bg"></div>
        <div class="brand flex items-center">
          <Link to="/">
            <Img
              className="mr-3"
              fixed={treeQuery.file.childImageSharp.fixed}
              alt="tree on fire"
            />
          </Link>
          <div class="brand-info ">
            <Link to="/">
              <div class="text-xl">Jóvenes Discípulos</div>
            </Link>
          </div>
        </div>

        <button
          type="button"
          class="navbar-toggle"
          data-toggle="collapse"
          data-target="#navbar-collapse"
          aria-expanded="false"
          onClick={() => {
            setMenuStyle("menu-is-open")
          }}
        >
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>

        <div class="contacts d-none d-md-block">
          {/* <div class="contact-item">+52 55555555</div> */}
          {/* <div class="contact-item spacer">/</div> */}
          <div class="contact-item">
            {/* <a href="mailto:contact@ramsay.com">
              contact@jovenesdiscipulos.com
            </a> */}
          </div>
        </div>
      </header>
    </Fragment>
  )
}
