import React from "react"
import { Link } from "gatsby"

export default function WhatYouCanFindInPage() {
  return (
    <div
      data-anchor="page2"
      id="page2"
      className="text-white section section-2"
    >
      <div className="scroll-wrap">
        <div className="scrollable-content">
          <div className="vertical-title text-white  d-none d-lg-block">
            <span>what i do</span>
          </div>
          <div className="vertical-centred">
            <div className="boxed boxed-inner">
              <div className="boxed">
                <div className="container">
                  <div className="intro">
                    <h2 className="title mb-5 pb-5">
                      {" "}
                      ¿
                      <span className="text-primary">
                        Qué puedes encontrar
                      </span>{" "}
                      en Jóvenes Discípulos?
                    </h2>
                    <div className="row-specialization row">
                      <div className="col-specialization col-md-6 col-lg-4">
                        <div
                          data-sal="slide-up"
                          data-sal-duration="1000"
                          data-sal-delay="300"
                          className="icon-specialization icon-film"
                        ></div>
                        <h4 className="text-uppercase">Material en video</h4>
                        <p>
                          A través de nuestro
                          <a
                            href="https://www.youtube.com/user/jovenesdiscipulos"
                            classNameName="text-primary"
                            target="_blank"
                          >
                            {" canal de youtube "}
                          </a>
                          puedes acceder a muchas predicas y alabanzas en
                          formato de video.
                        </p>
                      </div>
                      <div className="col-specialization col-md-6 col-lg-4">
                        <div
                          data-sal="slide-up"
                          data-sal-duration="1000"
                          data-sal-delay="300"
                          className="icon-specialization icon-bullhorn"
                        ></div>
                        <h4 className="text-uppercase">Material en audio</h4>
                        <p>
                          El contenido de audio estará disponible para
                          descargar. En nuestra sección:
                          <Link to="/page-2"> Descargar audios.</Link>
                        </p>
                      </div>
                      <div className="col-specialization col-md-6 col-lg-4">
                        <div
                          data-sal="slide-up"
                          data-sal-duration="1000"
                          data-sal-delay="300"
                          className="icon-specialization icon-book"
                        ></div>
                        <h4 className="text-uppercase">Material en texto</h4>
                        <p>
                          Puedes ver contenido en formato pdf en la sección:
                          <Link to="/texts">{" Material y revistas"}</Link>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
